<template>
	<div>
		<div class="table-responsive">
			<table class="table b-table table-fixed table-bordered">
				<thead>
					<tr>
						<th>No</th>
						<th>Creator Name</th>
						<th>Creator Email</th>
						<th v-show="showSelected === 'follower' || showSelected === null">
							<div class="d-flex" style="gap: 10px;">
								<b-img
									class="filter-image"
									:class="filter.sort_type == 'follower' && filter.sort_by == 'desc' ? 'rotate-180' : ''"
									:src="require('@/assets/images/icons/Icon-order-list-down.svg')"
									alt="Icon-order-list-down"
									role="button"
									@click="typeSort(filter.sort_type = 'follower', filter.sort_by == 'desc' ? filter.sort_by = 'asc' : filter.sort_by = 'desc', showSelected)"
								/>
								<span>Followers</span>
							</div>
						</th>
						<th v-show="showSelected === 'following' || showSelected === null">
							<div class="d-flex" style="gap: 10px;">
								<b-img
									class="filter-image"
									:class="filter.sort_type == 'following' && filter.sort_by == 'desc' ? 'rotate-180' : ''"
									:src="require('@/assets/images/icons/Icon-order-list-down.svg')"
									alt="Icon-order-list-down"
									role="button"
									@click="typeSort(filter.sort_type = 'following', filter.sort_by == 'desc' ? filter.sort_by = 'asc' : filter.sort_by = 'desc', showSelected)"
								/>
								<span>Following</span>
							</div>
						</th>
						<th v-show="showSelected === 'status' || showSelected === null">
							<div class="d-flex" style="gap: 10px;">
								<b-img
									class="filter-image"
									:class="filter.sort_type == 'status' && filter.sort_by == 'desc' ? 'rotate-180' : ''"
									:src="require('@/assets/images/icons/Icon-order-list-down.svg')"
									alt="Icon-order-list-down"
									role="button"
									@click="typeSort(filter.sort_type = 'status', filter.sort_by == 'desc' ? filter.sort_by = 'asc' : filter.sort_by = 'desc', showSelected)"
								/>
								<span>Post Status</span>
							</div>
						</th>
						<th v-show="showSelected === 'video' || showSelected === null">
							<div class="d-flex" style="gap: 10px;">
								<b-img
									class="filter-image"
									:class="filter.sort_type == 'video' && filter.sort_by == 'desc' ? 'rotate-180' : ''"
									:src="require('@/assets/images/icons/Icon-order-list-down.svg')"
									alt="Icon-order-list-down"
									role="button"
									@click="typeSort(filter.sort_type = 'video', filter.sort_by == 'desc' ? filter.sort_by = 'asc' : filter.sort_by = 'desc', showSelected)"
								/>
								<span>Post Video</span>
							</div>
						</th>
						<th v-show="showSelected === 'article' || showSelected === null">
							<div class="d-flex" style="gap: 10px;">
								<b-img
									class="filter-image"
									:class="filter.sort_type == 'article' && filter.sort_by == 'desc' ? 'rotate-180' : ''"
									:src="require('@/assets/images/icons/Icon-order-list-down.svg')"
									alt="Icon-order-list-down"
									role="button"
									@click="typeSort(filter.sort_type = 'article', filter.sort_by == 'desc' ? filter.sort_by = 'asc' : filter.sort_by = 'desc', showSelected)"
								/>
								<span>Post Article</span>
							</div>
						</th>
						<th v-show="showSelected === 'thread' || showSelected === null">
							<div class="d-flex" style="gap: 10px;">
								<b-img
									class="filter-image"
									:class="filter.sort_type == 'thread' && filter.sort_by == 'desc' ? 'rotate-180' : ''"
									:src="require('@/assets/images/icons/Icon-order-list-down.svg')"
									alt="Icon-order-list-down"
									role="button"
									@click="typeSort(filter.sort_type = 'thread', filter.sort_by == 'desc' ? filter.sort_by = 'asc' : filter.sort_by = 'desc', showSelected)"
								/>
								<span>Tanya Master</span>
							</div>
						</th>
						<th v-show="showSelected === 'likes' || showSelected === null">
							<div class="d-flex" style="gap: 10px;">
								<b-img
									class="filter-image"
									:class="filter.sort_type == 'likes' && filter.sort_by == 'desc' ? 'rotate-180' : ''"
									:src="require('@/assets/images/icons/Icon-order-list-down.svg')"
									alt="Icon-order-list-down"
									role="button"
									@click="typeSort(filter.sort_type = 'likes', filter.sort_by == 'desc' ? filter.sort_by = 'asc' : filter.sort_by = 'desc', showSelected)"
								/>
								<span>Liked Amount</span>
							</div>
						</th>
						<th v-show="showSelected === 'comment' || showSelected === null">
							<div class="d-flex" style="gap: 10px;">
								<b-img
									class="filter-image"
									:class="filter.sort_type == 'comment' && filter.sort_by == 'desc' ? 'rotate-180' : ''"
									:src="require('@/assets/images/icons/Icon-order-list-down.svg')"
									alt="Icon-order-list-down"
									role="button"
									@click="typeSort(filter.sort_type = 'comment', filter.sort_by == 'desc' ? filter.sort_by = 'asc' : filter.sort_by = 'desc', showSelected)"
								/>
								<span>Comment Amount</span>
							</div>
						</th>
						<th v-show="showSelected === 'shares' || showSelected === null">
							<div class="d-flex" style="gap: 10px;">
								<b-img
									class="filter-image"
									:class="filter.sort_type == 'shares' && filter.sort_by == 'desc' ? 'rotate-180' : ''"
									:src="require('@/assets/images/icons/Icon-order-list-down.svg')"
									alt="Icon-order-list-down"
									role="button"
									@click="typeSort(filter.sort_type = 'shares', filter.sort_by == 'desc' ? filter.sort_by = 'asc' : filter.sort_by = 'desc', showSelected)"
								/>
								<span>Share Postingan</span>
							</div>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr v-if="isLoadingComponent">
						<td
							colspan="12"
							class="text-center"
						>
						<b-spinner
							class="mb-1"
							variant="primary"
						/><br>
							Loading...
						</td>
					</tr>
					<tr
						v-for="item, index in userActivityList"
						:key="item.id"
					>
						<td>
							{{ (currentPageList - 1) * 10 + index + 1 }}
						</td>
						<td>
							{{ item.creator_name || '-' }}
						</td>
						<td>
							{{ item.creator_email || '-' }}
						</td>
						<td v-show="showSelected === 'follower' || showSelected === null">
							{{ item.count_follower || '-'}}
						</td>
						<td v-show="showSelected === 'following' || showSelected === null">
							{{ item.count_following || '-'}}
						</td>
						<td v-show="showSelected === 'status' || showSelected === null">
							{{ item.count_post_status || '-'}}
						</td>
						<td v-show="showSelected === 'video' || showSelected === null">
							{{ item.count_post_video || '-' }}
						</td>
						<td v-show="showSelected === 'article' || showSelected === null">
							{{ item.count_post_article || '-' }}
						</td>
						<td v-show="showSelected === 'thread' || showSelected === null">
							{{ item.count_thread || '-' }}
						</td>
						<td v-show="showSelected === 'likes' || showSelected === null">
							{{ item.liked_amount || '-' }}
						</td>
						<td v-show="showSelected === 'comment' || showSelected === null">
							{{ item.comment_amount || '-' }}
						</td>
						<td v-show="showSelected === 'shares' || showSelected === null">
							{{ item.count_shares || '-' }}
						</td>
					</tr>
					<tr v-if="resultData.total == 0 && !isLoadingComponent">
						<td
							colspan="12"
							class="text-center"
						>
							Data is empty.
						</td>
					</tr>
				</tbody>
			</table>

			<div
				v-if="resultData.total > 0"
				class="m-1"
			>
				<div class="row">
					<div class="col mb-1">
						<small>Showing {{ resultData.from }} to {{ resultData.to }} from {{ resultData.total }}</small>
					</div>
					<div class="col">
						<pagination
							:data="resultData"
							:limit="4"
							align="right"
							@pagination-change-page="getData"
						/>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import { 
	BTable,
	BSpinner,
	BBadge,
	BImg,
	BDropdown,
  	BDropdownItem,
} from 'bootstrap-vue'
import _ from 'lodash'
export default {
	components: {
		BTable,
		BSpinner,
		BBadge,
		BImg,
		BDropdown,
		BDropdownItem,
	},
	props: {
		userActivity: {
			type: '',
		},
		result: {
			type: '',
		},
		isLoading: {
			type: Boolean,
		},
		currentPage: {
			type: Number,
		},
		typeSort: {
			type: Function,
		},
		getData: {
			type: Function,
		},
		showSelected: {
			type: String
		}
	},
	watch: {
		isLoading(value) {
			this.isLoadingComponent = value
		},
		userActivity(value) {
			this.userActivityList = value
		},
		currentPage(value) {
			this.currentPageList = value
		},
		result(value) {
			this.resultData = value
		},
		showSelected(value) {
			this.showSelected = value;
		},
		filter: {
			handler(value) {
				this.$emit('filter', value)
			},
			deep: true
		}
	},
	data() {
		return {
			isLoadingComponent: true,
			currentPageList: 1,
			resultData:{},
			userActivityList: [],
			filter: {
				sort_type: '',
				sort_by: '',
				show: ''
			},
			path: process.env.URL_LANDING_PAGE,
		}
	},
	computed: {
		rows() {
			return this.userActivityList.length
		}
	},
	methods: {
		
	}
}
</script>

<style>
.text-detail {
	width: 350px;
}
.table tbody tr:not([class*=table-]) td {
	vertical-align: top;
}
</style>