<template>
	<b-card title="User Activity List">
		<b-row class="mb-3">
			<!-- <b-col cols="12" lg="3">
				<div class="form-group">
					<label for="category">Category:</label>
					<v-select
						id="category"
						v-model="filter.nameCategory"
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						label="content_category_name"
						placeholder="Pilih Category"
						:options="category"
						@input="inputSubCategory($event.content_category_slug)"
					/>
				</div>
			</b-col>

			<b-col cols="12" lg="3">
				<div class="form-group">
					<label for="subCategory">Sub Category:</label>
					<v-select
						id="subCategory"
						v-model="filter.subNameCategory"
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						label="content_subcategory_name"
						placeholder="Pilih Sub Category"
						:options="subCategory"
					/>
				</div>
			</b-col> -->

			<b-col cols="12" lg="3">
				<div class="form-group">
					<label for="username">Username:</label>
					<input 
						id="username"
						type="text" 
						class="form-control"
						placeholder="Search: Username"
						v-model="filter.user_name" 
					>
				</div>
			</b-col>

			<b-col cols="12" lg="3">
				<div class="form-group">
					<label for="email">Email:</label>
					<input 
						id="email"
						type="text" 
						class="form-control"
						placeholder="Search: Email"
						v-model="filter.email" 
					>
				</div>
			</b-col>

			<b-col cols="12" lg="3">
				<div class="form-group">
					<label>Start Date:</label>
					<flat-pickr
						v-model="filter.start_date"
						class="form-control"
						placeholder="Search: Date"
						:config="{ 
							maxDate: new Date(),
							locale: {
								rangeSeparator: ' - ',
							},
						}"
					/>
				</div>
			</b-col>

			<b-col cols="12" lg="3">
				<div class="form-group">
					<label>End Date:</label>
					<flat-pickr
						v-model="filter.end_date"
						class="form-control"
						placeholder="Search: Date"
						:config="{ 
							maxDate: new Date(),
							locale: {
								rangeSeparator: ' - ',
							},
						}"
					/>
				</div>
			</b-col>

			<b-col cols="12" lg="3">
				<div class="form-group">
					<label for="show">Show:</label>
					<v-select
						id="show" 
						v-model="filter.show"
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						label="title"
						placeholder="Pilih Type"
						:options="show"
						:reduce="show => show.value"
					/>
				</div>
			</b-col>

	
			<b-col cols="2" class="mt-2">
				<button
					class="btn btn-outline-secondary mr-2 w-100"
					@click="filter = {}" 
				>
					Reset
				</button>
			</b-col>
			<b-col cols="2" class="mt-2">
					<button
					class="btn btn-primary w-100"
					v-ripple.400="'rgba(113, 102, 240, 0.15)'"
					v-b-modal.form-modal-export-csv
					@click="getDataExport">
						Export
					</button>

			</b-col>
			<Export
				:is-loading="isLoadingExport"
				:result-export="resultExport"
				:export-now="exportNow"
			/>
		</b-row>
		
		<Table 
			:result="result"
			:user-activity="userActivity"
			:is-loading="isLoading"
			:get-data="getData"
			:current-page="currentPage"
			:type-sort="typeSort"
			:show-selected="showSelected"
		/>
	</b-card>
</template>

<script>
import Table from '@/components/user-activity/Table.vue'
import { successNotification, errorNotification } from '@/auth/utils'
import { 
	BRow, 
	BCol, 
	BCard,
	BTable,
	BFormInput,  
	BButton, 
	BSpinner,
	VBModal,
} from 'bootstrap-vue'
import Export from '@/components/ExportCsv.vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import _ from 'lodash'
export default {
	components: {
		Export,
		Table,
		BCard,
		BTable,
		BCol,
		BRow,
		BButton,
		BSpinner,
		BFormInput,
		vSelect,
		flatPickr,
	},
	directives: {
	'b-modal': VBModal,
		Ripple,
	},
	data() {
		return {
			export_choice: 0,
			currentPage: 1,
			isLoading: false,
			result: [],
			userActivity: {},
			link:'',
			isLoadingExport: false,
			filter: {},
			showSelected: null,
			resultExport: [],
			category: [],
			subCategory:[],
			sort: '',
			show: [
				{ 
					title: 'Followers',
					value: 'follower'
				}, 
				{ 
					title: 'Following',
					value: 'following'
				}, 
				{ 
					title: 'Post Status',
					value: 'status'
				},
				{ 
					title: 'Post Video',
					value: 'video'
				},
				{ 
					title: 'Post Article',
					value: 'article'
				},
				{ 
					title: 'Tanya Master',
					value: 'thread'
				},
				{ 
					title: 'Liked Amount',
					value: 'likes'
				},
				{ 
					title: 'Comment Amount',
					value: 'comment'
				},
				{ 
					title: 'Share Postingan',
					value: 'shares'
				}
			],
		}
	},
	watch: {
		filter: {
			handler: _.debounce(function () {
				this.filter.id_category_content = this.filter.nameCategory ? this.filter.nameCategory.id : '',
				this.filter.id_subcategory_content = this.filter.subNameCategory ? this.filter.subNameCategory.id : ''
				this.getData();
				this.showDataByType(this.filter.show ?? null);
		
			}, 1000),
			deep: true,
		},
	},
	setup(props) {
		return {
			successNotification,
			errorNotification,
		}
	},
	mounted() {
		this.categoryName();
	},
	methods: {
		typeSort(value1, value2, value3) {
			this.filter = {
				sort_type : value1,
				sort_by : value2,
				show : value3
			}
		},
		
		inputSubCategory(slug) {
			this.$http.get(`/admin/sub-category-contents?content_category_slug=${slug}`)
			.then(response => {
				this.subCategory = response.data.data
				this.filter.id_subcategory_content = ''
			})
		},
		categoryName() {
			this.$http.get('/admin/category-contents')
			.then(response => {
				this.category = response.data.data
			})
		},
		VaidateEmail(email){
			return email.match(
				/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
		},
		getData(page) {
			this.isLoading = true
			this.currentPage = page
			const queryParams = this.filter
    		queryParams.page = page

			if (typeof queryParams['email'] !== 'undefined'){
				if (queryParams['email'].length > 0 && this.VaidateEmail(queryParams['email']) === null) {
					return errorNotification(this, 'error Email', "format Email Salah")			
				}
			}

			this.$http.get('/admin/get-list-user-activity', {
				params: queryParams,
			}).then(response => {
				this.result = response.data.data
				this.userActivity = response.data.data.data
				this.currentPage = response.data.data.current_page
				this.isLoading = false
			}).catch(error => {
				const result = error.response.data.meta.validations
				for (const x in result) {
					errorNotification(this, `error ${x}`, result[x][0])
				}
			})	
		},
		showDataByType(value){
			this.showSelected = value;
		},
		getDataExport() {
			this.isLoadingExport = true

			this.$http.get('/admin/export/user-activity/list'
			).then(response => {
				console.log(response.data.data)
				this.resultExport = response.data.data
				this.isLoadingExport = false
			}).catch(error => {
			})
		},
		exportNow() {
			// this.isLoadingExport = true
			const exportParam = this.filter
			exportParam.per_page = null
			this.$swal({
						title: 'Sukses',
						icon: 'success',
						text: 'Mengexport data di background, mohon tunggu beberapa saat.',
						type: 'warning',
						customClass: {
							confirmButton: 'btn btn-primary',
						},
						buttonsStyling: false,
					}) 
			this.$bvModal.hide('form-modal-export-csv')
			this.$http.post('/admin/export/user-activity/export', exportParam).then(response => {
				if(response) {
					// this.$swal({
					// 	title: 'Sukses',
					// 	icon: 'success',
					// 	text: 'Mengexport data di background, mohon tunggu beberapa saat.',
					// 	type: 'warning',
					// 	customClass: {
					// 		confirmButton: 'btn btn-primary',
					// 	},
					// 	buttonsStyling: false,
					// }) 
					console.log("success")
					// this.isLoadingExport = false
				}
			}).catch(error => {
				if (error.response.data.meta.validations) {
					this.validations = error.response.data.meta.validatiosns
				}
				return errorNotification(this, 'error Export', "Terjadi Kesalah Saat Export")	
			})
		},
	},
	created() {
		this.getData(this.currentPage)
	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>